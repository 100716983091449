import React from "react";
import "./Policies.css";

const ReturnsAndRefund = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Returns and Refund Policy</h1>
      <p className="privacy-policy-intro">
      Effective Date: 01 September 2024
      Last Updated: 01 January 2025

      </p>
      <p className="privacy-policy-intro">
      At Giftingy, customer satisfaction is our priority. However, due to the nature of our products, we maintain the following Returns and Refund Policy:
      </p>
      
      <section className="privacy-section">
        <h2>No Returns or Refunds</h2>       
          <ul>
            <li>We do not accept returns or process refunds once an order has been delivered, unless the product is found damaged upon delivery.</li>
          </ul>
      </section>
      <section className="privacy-section">
        <h2>Damage Claims</h2> 
        <p>If a product is damaged during delivery, you may initiate a damage claim by adhering to the following guidelines:</p>   
        <ol><li><strong>Unboxing Video Required:</strong>
            <ul>
                <li>A clear unboxing video showing the damaged product must be provided as proof.</li>
                <li>The video must capture the unopened package and the process of opening it to validate the damage occurred during transit.</li>
            </ul>            
            </li>
        </ol>  
        <ol start="2"><li><strong>Timely Reporting:</strong>
            <ul>
                <li>The claim must be reported within 48 hours of receiving the delivery.</li>
                <li>Late submissions will not be considered for evaluation.</li>
            </ul>            
            </li>
        </ol>  

      </section>

      <section className="privacy-section">
        <h2>Claim Process</h2>     
          <ol>
            <li><strong>Contact Support:</strong> Email the unboxing video, order details, and a brief description of the issue to <a href="mailto:vineet@giftingy.com">vineet@giftingy.com.</a></li>
            <li><strong>Evaluation:</strong> Our team will review the submitted evidence and respond within 5 business days.</li>
            <li><strong>Resolution:</strong> If the claim is approved, a replacement will be shipped, or a refund will be processed as per the customer’s preference.</li>
          </ol>
      </section>



      <section className="privacy-section">
        <h2>Important Notes</h2>     
          <ul>
            <li>Products damaged due to misuse, improper handling, or failure to follow care instructions are not eligible for claims.</li>
            <li>No refunds or returns will be entertained for customised or personalised items unless damaged during delivery.</li>
          </ul>
      </section>


      <footer className="privacy-footer">
        <p>
        For further queries or clarifications, contact us at <a href="mailto:vineet@giftingy.com">vineet@giftingy.com </a> or call us at .<a href="tel:+919990158777">+91 9990158777.</a>
        </p>
      </footer>
    </div>
  );
};

export default ReturnsAndRefund;
