import React, { useState } from 'react';
import './Categories.css';
import Popup from './Popup'; // Import the Popup component
// Mock Data for Categories and Products with Image URLs
const categories = [
  { id: 1, name: 'Luxury Home Decor', image: '/images/icon1.png' },
  { id: 2, name: 'Health & Beauty', image: '/images/icon2.png' },
  { id: 3, name: 'Electronic Gadgets', image: '/images/icon3.png' },
  { id: 4, name: 'Divinity Gifting', image: '/images/icon4.png' },
  { id: 5, name: 'Medals & Trophies', image: '/images/icon7.png' },
  { id: 6, name: 'Vastu Shanti Gifting', image: '/images/icon8.png' },
];

const products = {
  1: [
    { id: 1, name: 'Flower  Box', image: '/images/catimg1.jpg', catalog: 'https://example.com/catalog1.pdf' },
    { id: 2, name: 'Brass Vase', image: '/images/catimg4.jpg', catalog: 'https://example.com/catalog2.pdf' },
    { id: 3, name: 'Scented Candles', image: '/images/catimg3.jpg', catalog: 'https://example.com/catalog3.pdf' },
    { id: 4, name: 'Wall Mirror', image: '/images/catimg2.jpg', catalog: 'https://example.com/catalog4.pdf' },
  ],
  2: [
    { id: 5, name: 'Nuts & Almonds', image: '/images/health1.jpg', catalog: 'https://example.com/catalog5.pdf' },
    { id: 6, name: 'Face Care', image: '/images/health3.jpg', catalog: 'https://example.com/catalog6.pdf' },
    { id: 7, name: 'Fragrances', image: '/images/health2.jpg', catalog: 'https://example.com/catalog7.pdf' },
    { id: 8, name: 'Cashews', image: '/images/health4.jpg', catalog: 'https://example.com/catalog8.pdf' },
  ],
  3: [
    { id: 9, name: 'Mini fan', image: '/images/electronic1.jpg', catalog: 'https://example.com/catalog5.pdf' },
    { id: 10, name: 'Digital Clock', image: '/images/electronic2.jpg', catalog: 'https://example.com/catalog6.pdf' },
    { id: 11, name: 'Lamps', image: '/images/electronic3.jpg', catalog: 'https://example.com/catalog7.pdf' },
    { id: 12, name: 'Steam Iron', image: '/images/electronic4.jpg', catalog: 'https://example.com/catalog8.pdf' },
  ],
  4: [
    { id: 13, name: 'Hindu Idols', image: '/images/divinity1.jpg', catalog: 'https://example.com/catalog5.pdf' },
    { id: 14, name: 'Holy Books', image: '/images/divinity2.jpg', catalog: 'https://example.com/catalog6.pdf' },
    { id: 15, name: 'Asthetic Idols', image: '/images/divinity3.jpg', catalog: 'https://example.com/catalog7.pdf' },
    { id: 16, name: 'Pendants', image: '/images/divinity4.jpg', catalog: 'https://example.com/catalog8.pdf' },
  ],
  5: [
    { id: 17, name: 'Trophy Cups', image: '/images/medtr1.jpg', catalog: 'https://example.com/catalog5.pdf' },
    { id: 18, name: 'Medals', image: '/images/medtr2.jpg', catalog: 'https://example.com/catalog6.pdf' },
    { id: 19, name: 'Trophies', image: '/images/medtr3.jpg', catalog: 'https://example.com/catalog7.pdf' },
    { id: 20, name: 'Elegant Trophies', image: '/images/medtr4.jpg', catalog: 'https://example.com/catalog8.pdf' },
  ],
  6: [
    { id: 21, name: 'Brass Turtle', image: '/images/vastu1.jpg', catalog: 'https://example.com/catalog5.pdf' },
    { id: 22, name: 'Vastu Corner', image: '/images/vastu2.jpg', catalog: 'https://example.com/catalog6.pdf' },
    { id: 23, name: 'Lucky Bamboo', image: '/images/vastu3.jpg', catalog: 'https://example.com/catalog7.pdf' },
    { id: 24, name: 'Laughing Buddha', image: '/images/vastu4.jpg', catalog: 'https://example.com/catalog8.pdf' },
  ],
  // Additional products for other categories...
};

function Categories() {
  const [activeCategory, setActiveCategory] = useState(1); // Default active category
  const [selectedProducts, setSelectedProducts] = useState(products[activeCategory]);

  const handleCategoryClick = (id) => {
    setActiveCategory(id);
    setSelectedProducts(products[id] || []); // Fetch products based on category id
  };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div id='shop' className="categories-container">
      <h2 className="categories-header">
        <i className="fas fa-crown"></i> Our Categories <i className="fas fa-crown"></i>
      </h2>
      <div className="categories-grid">
        {categories.map(category => (
          <div
            key={category.id}
            className={`category-box ${activeCategory === category.id ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category.id)}
          >
            <div className="category-icon">
              <img src={category.image} alt={category.name} />
            </div>
            <h3 className='headingStyle'>{category.name}</h3>
          </div>
        ))}
      </div>

      <div className="products-container">
        <div className="products-grid">
          {selectedProducts.map(product => (
            <div key={product.id} className="product-box">
              <img src={product.image} alt={product.name} />
              <p>{product.name}</p>
              <div className="product-actions">
                <a href="https://wa.me/9990168777" target="_blank" rel="noopener noreferrer" className="product-button">
                  <img className='product-button1' src="/images/whatsaapicon.png"></img>
                </a>
                <a href={`/contact`} className="product-button" onClick={(e) => { e.preventDefault(); togglePopup(); }}>Enquire Now</a>
              </div>
              
            </div>
          ))}
        </div>
      </div>
      <Popup showPopup={showPopup} togglePopup={togglePopup} />
    </div>
  );
}

export default Categories;
