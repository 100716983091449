import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './Footer.css'; // Ensure you create and link a CSS file for custom styles

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p className='footerdisclaimer'> 
          <b>Disclaimer</b> The content on this Website, <NavLink to='/'>https://www.giftingy.com</NavLink> ("the Website"), including images, product descriptions, and catalogues (including attached PDFs), is provided for informational purposes only. By accessing and using the Website, you agree to the terms outlined in this disclaimer. If you disagree with any part of this disclaimer, please refrain from using the Website. <NavLink className='readMore' to='/disclaimer'>Read More</NavLink>
        </p>
        <div className="row">
          <div className="col-md-3">
            <h5 className="footer-heading textWhite logs"> 
              <NavLink to='/'>Giftingy</NavLink>
            </h5>
            <div>
              <span>
                Giftingy.com is owned and operated by Enlargenet GST no. – 06ADIPB5368H3ZJ Regd.
              </span>
            </div>
            <h5 className="footer-heading topmar">Address</h5>
            <address className='footeradd'>
              301-C, 3rd Floor, Supermart 2 <br />
              Dlf Phase IV, Gurgaon<br />
              <a href="tel:919990168777">+91-9990168777</a><br />
              <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a>
            </address>
            {/* <div className="social-icons nomargin">
              <a href="#"><i className="fab fa-facebook-f"></i></a>
              <a href="#"><i className="fab fa-twitter"></i></a>
              <a href="#"><i className="fab fa-linkedin-in"></i></a>
              <a href="#"><i className="fab fa-instagram"></i></a>
            </div> */}
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Services</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/services/corporate-gifting">Corporate Gifting</NavLink></li>
              <li><NavLink to="/services/gifts-for-her">Gifts For Her/Him</NavLink></li>
              <li><NavLink to="/services/festival-gifting">Festive Gifting</NavLink></li>
              <li><NavLink to="/services/luxury-home-decor-gifting">Home Decor</NavLink></li>
              <li><NavLink to="/services/wedding-return-gifts">Wedding Return Gifts</NavLink></li>
              <li><NavLink to="/services/dryfruits-and-sweets-gifting">Dry Fruits & Sweets</NavLink></li>
              <li><NavLink to="/services/branded-medals-and-trophies">Medals & Trophies</NavLink></li>
              <li><NavLink to="/services/Vastu-corporate-gifting">Vastu Gifting</NavLink></li>
              <li><NavLink to="/services/divinity-gifts">Divinity Gifting</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Quick Links</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/about">About Us</NavLink></li>
              <li><NavLink to="https://giftingy.com/blogs">Blog</NavLink></li>
              <li><NavLink to="/contact">Contact</NavLink></li>
              <li><NavLink to="/disclaimer">Disclaimer</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Trending Products</h5>
            <div className="image-row">
              <img className='imageZoomm' src='/images/imaage3.jpg' alt='Blog Image'></img>
              <img className='imageZoomm' src='/images/imaage2.jpg' alt='Blog Image'></img>
            </div>
            <div className="image-row">
              <img className='imageZoomm' src='/images/imaage1.jpg' alt='Blog Image'></img>
              <img className='imageZoomm' src='/images/imaage4.jpg' alt='Blog Image'></img>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className='col-md-7'>
          <p className="footer-text">
              &copy; 2024 Giftingy. All rights reserved. | Designed & Developed By <a target='_blank' href='https://www.conceptualise.in'>Conceptualise</a>
            </p>
          </div>
          <div className='col-md-5'>
          <NavLink to="/privacy/policy">Privacy Policy  | </NavLink>
          <NavLink to="/terms-and-policy">Terms And Policy  | </NavLink>
          <NavLink to="/returns-and-refunds"> Returns &amp; Refund</NavLink>

          </div>
         
        </div>
      </div>
    </footer>
  );
}

export default Footer;
