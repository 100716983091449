import React, { useEffect } from 'react'; // Import useEffect
import { Routes, Route, useLocation } from 'react-router-dom';
// Import matchMedia polyfill for Jest environment
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import LocalBusinessSchema from './components/LocalBusinessSchema'; // Import LocalBusinessSchema
import Header from './components/Header';
import Footer from './components/Footer';
import FooterCategory from './components/FooterCategory';
import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import { Thankyou } from './pages/Thankyou';
import ProductDetail from './components/ProductDetail';
import CorporateGifting from './pages/services/CorporareGifting';
import LuxuryHomeDecor from './pages/services/LuxuryHomeDecor'; // Adjust the path as necessary
import DivinityGifts from './pages/services/DivinityGifts'; 
import IncentiveGifts from './pages/services/IncentiveGifts'; 
import PersonalisedCorporateGifts from './pages/services/PersonalisedCorporateGifts'; 
import IndianTraditionalGifts from './pages/services/IndianTraditionalGifts'; 
import LuxuryGifting from './pages/services/LuxuryGifting'; 
import ExhibitionAndEventsGifting from './pages/services/ExhibitionAndEventsGifting';
import WeddingReturnGifts from './pages/services/WeddingReturnGifts';
import FestivalGifting from './pages/services/FestivalGifting';
import DiwaliCorporateGifting from './pages/services/DiwaliCorporateGifting';
import EidCorporateGifting from './pages/services/EidCorporateGifting';
import ChocolateCorporateGifting from './pages/services/ChocolateCorporateGifting';
import BrandedMedalsAndTrophies from './pages/services/BrandedMedalsAndTrophies';
import DryFruitsAndSweets from './pages/services/DryFruitsandSweets';
import BarAccessoriesCorporateGifting from './pages/services/BarAccessoriesCorporateGifting';
import VastuCorporateGifting from './pages/services/VastuCorporateGifting';
import NavratriCorporateGifting from './pages/services/NavratriCorporateGifting';
import AkshayTritiyaCorporateGifting from './pages/services/AkshayTritiyaCorporateGifting';
import DhanterasCorporateGifting from './pages/services/DhanterasCorporateGifting';
import GiftsforHer from './pages/services/GiftsforHer';
import GiftsForMale from './pages/services/GiftsForMale';
import PongalCorporateGifting from './pages/services/PongalCorporateGifting';
import KartikayDibamGifting from './pages/services/KartikayDibamGifting';
import MilestonesAndCelebrations from './pages/services/MilestonesAndCelebrations';
import GolfCorporateGifting from './pages/services/GolfCorporateGifting';
import CorporateLeatherGifting from './pages/services/CorporateLeatherGifting';
import GiftsforClients from './pages/services/GiftsforClients';
import GiftsforEmployees from './pages/services/GiftsforEmployees';
import HealthAndWellnessGifts from './pages/services/HealthAndWellnessGifts';
import OnboardingEmployeeKit from './pages/services/OnboardingEmployeeKit';
import BrandedApparelGifting from './pages/services/BrandedApparelGifting';
import PlantersCorporateGifting from './pages/services/PlantersCorporateGifting';
import TravelGiftsforBusinesses from './pages/services/TravelGiftsforBusinesses';
import DussehraCorporateGifts from './pages/services/DussehraCorporateGifts';
import EasterCorporateGifts from './pages/services/EasterCorporateGifts';
import GaneshChaturthiCorporateGifting from './pages/services/GaneshChaturthiCorporateGifting';
import MakarSankrantiCorporateGifts from './pages/services/MakarSankrantiCorporateGifts';
import KitchenandDiningCorporateGifting from './pages/services/KitchenandDiningCorporateGifting';
import BeautyAndFashionGifting from './pages/services/BeautyAndFashionGifting';
import CorporateJewelryGifts from './pages/services/JewelleryCorporateGifts';
import PromotionalGiveawaysCorporateGifts from './pages/services/PromotionalGiveaways';
import HandicraftCorporateGifting from './pages/services/HandicraftCorporateGifting';
import FoodandSpicesGiftng from './pages/services/FoodandSpicesGiftng';
import TeaCorporateGifts from './pages/services/TeaCorporateGifts';
import CoffeeAsCorporateGift from './pages/services/CoffeeAsCorporateGift';
import BlanketsforCorporateGifting from './pages/services/BlanketsforCorporateGifting';
import DrinkwareProductsforCorporateGifts from './pages/services/DrinkwareProductsforCorporateGifts';
import BackpackAsCorporateGift from './pages/services/BackpackAsCorporateGift';
import DiaryCorporateGift from './pages/services/DiaryCorporateGift';
import UtilityCorporateGift from './pages/services/UtilityCorporateGift';
import WomensDayCorporateGift from './pages/services/WomensDayCorporateGift';
import BedsheetsforCorporateGifting from './pages/services/BedsheetsforCorporateGifting';
import BrassItemsAsCorporateGifts from './pages/services/BrassItemsAsCorporateGifts';
import Disclaimer from './components/Disclaimer';
import { scrollToTop } from './components/scrollToTop';
// import Synopsis from '../src/components/Synopsis';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import 'wow.js/css/libs/animate.css'; // Import WOW animations CSS
import SliderForm from './components/SliderForm';
import TermsAndPolicy from './components/privacy/TermsAndPolicy';
import ReturnsAndRefund from './components/privacy/ReturnsAndRefund';
function App() {
  const location = useLocation();
  
  // Check if the current route is not "/contact"
  const showSliderForm = location.pathname !== '/contact';

  useEffect(() => {
    scrollToTop(); // Scroll to top on route change
  }, [location]);
  
  

  return (
    <div className="App">
      <LocalBusinessSchema /> {/* Add LocalBusinessSchema here */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/services/corporate-gifting" element={<CorporateGifting />} />
        <Route path="/services/luxury-home-decor-gifting" element={<LuxuryHomeDecor />} />
        <Route path="/services/personalised-corporate-gifts" element={<PersonalisedCorporateGifts />} />
        <Route path="/services/incentive-gifts" element={<IncentiveGifts />} />
        <Route path="/services/divinity-gifts" element={<DivinityGifts />} />
        <Route path="/services/indian-traditional-gifts" element={<IndianTraditionalGifts />} />
        <Route path="/services/luxury-gifting" element={<LuxuryGifting />} />
        <Route path="/services/exhibition-and-events-gifting" element={<ExhibitionAndEventsGifting />} />
        <Route path="/services/wedding-return-gifts" element={<WeddingReturnGifts />} />
        <Route path="/services/festival-gifting" element={<FestivalGifting />} />
        <Route path="/services/diwali-corporate-gifting" element={<DiwaliCorporateGifting />} />
        <Route path="/services/eid-corporate-gifting" element={<EidCorporateGifting />} />
        <Route path="/services/chocolate-corporate-gifting" element={<ChocolateCorporateGifting />} />
        <Route path="/services/branded-medals-and-trophies" element={<BrandedMedalsAndTrophies />} />
        <Route path="/services/dryfruits-and-sweets-gifting" element={<DryFruitsAndSweets />} />
        <Route path="/services/bar-accessories-corporate-gifting" element={<BarAccessoriesCorporateGifting />} />
        <Route path="/services/vastu-corporate-gifting" element={<VastuCorporateGifting />} />
        <Route path="/services/navratri-corporate-gifting" element={<NavratriCorporateGifting />} />
        <Route path="/services/akshay-tritiya-corporate-gifting" element={<AkshayTritiyaCorporateGifting />} />
        <Route path="/services/dhanteras-corporate-gifting" element={<DhanterasCorporateGifting />} />
        <Route path="/services/gifts-for-her" element={<GiftsforHer />} />
        <Route path="/services/gifts-for-male" element={<GiftsForMale />} />
        <Route path="/services/pongal-corporate-gifting" element={<PongalCorporateGifting />} />
        <Route path="/services/kartikay-dibam-corporate-gifting" element={<KartikayDibamGifting />} />
        <Route path="/services/corporate-gifting-for-milestones-and-Celebrations" element={<MilestonesAndCelebrations />} />
        <Route path="/services/golf-corporate-gifting" element={<GolfCorporateGifting />} />
        <Route path="/services/corporate-leather-gifting" element={<CorporateLeatherGifting />} />
        <Route path="/services/corporate-gifts-for-clients" element={<GiftsforClients />} />
        <Route path="/services/corporate-gifts-for-employees" element={<GiftsforEmployees />} />
        <Route path="/services/health-and-wellness-gifts" element={<HealthAndWellnessGifts />} />
        <Route path="/services/onboarding-employee-kit" element={<OnboardingEmployeeKit />} />
        <Route path="/services/branded-apparel-gifting" element={<BrandedApparelGifting />} />
        <Route path="/services/planters-corporate-gifting" element={<PlantersCorporateGifting />} />
        <Route path="/services/travel-gifts-for-businesses" element={<TravelGiftsforBusinesses />} />
        <Route path="/services/dussehra-corporate-gifts" element={<DussehraCorporateGifts />} />
        <Route path="/services/easter-corporate-gifts" element={<EasterCorporateGifts />} />
        <Route path="/services/ganesh-chaturthi-corporate-gifting" element={<GaneshChaturthiCorporateGifting />} />
        <Route path="/services/makar-sankranti-corporate-gifts" element={<MakarSankrantiCorporateGifts />} />
        <Route path="/services/kitchen-and-dining-corporate-gifting" element={<KitchenandDiningCorporateGifting />} />
        <Route path="/services/beauty-and-fashion-gifting" element={<BeautyAndFashionGifting />} />
        <Route path="/services/corporate-jewellery-gifts" element={<CorporateJewelryGifts />} />
        <Route path="/services/promotional-giveaways-corporate-gifts" element={<PromotionalGiveawaysCorporateGifts />} />
        <Route path="/services/handicraft-corporate-gifting" element={<HandicraftCorporateGifting />} />
        <Route path="/services/food-and-spices-gifting" element={<FoodandSpicesGiftng />} />
        <Route path="/services/tea-corporate-gifts" element={<TeaCorporateGifts />} />
        <Route path="/services/coffee-as-corporate-gift" element={<CoffeeAsCorporateGift />} />
        <Route path="/services/blankets-for-corporate-gifting" element={<BlanketsforCorporateGifting />} />
        <Route path="/services/drinkware-products-for-corporate-gifts" element={<DrinkwareProductsforCorporateGifts />} />
        <Route path="/services/backpack-as-corporate-gift" element={<BackpackAsCorporateGift />} />
        <Route path="/services/diary-corporate-gift" element={<DiaryCorporateGift />} />
        <Route path="/services/utility-corporate-gift" element={<UtilityCorporateGift />} />
        <Route path="/services/womens-day-corporate-gift" element={<WomensDayCorporateGift />} />
        <Route path="/services/bedsheets-for-corporate-gifting" element={<BedsheetsforCorporateGifting />} />
        <Route path="/services/brass-items-as-corporate-gifts" element={<BrassItemsAsCorporateGifts />} />
        <Route path="/privacy/policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-policy" element={<TermsAndPolicy />} />
        <Route path="/returns-and-refunds" element={<ReturnsAndRefund />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
      <FooterCategory />
      <Footer />
      {showSliderForm && <SliderForm />}
     
   
    </div>
  );
}

export default App;
