import React, { useState } from 'react';
import '../components/SaleProduct.css'; // Import the CSS file for styling
import Popup from '../components/Popup'; // Import the Popup component

const SaleProduct = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const images = [
    {
      src: '/images/img1.jpg',
      alt: 'Image 1',
      description: 'Product Description 1',
      link: '/services/festival-gifting',
    },
    {
      src: '/images/img2.jpg',
      alt: 'Image 2',
      description: 'Product Description 2',
      link: '/services/handicraft-corporate-gifting',
    },
    {
      src: '/images/img3.jpg',
      alt: 'Image 3',
      description: 'Product Description 3',
      link: '/services/dryfruits-and-sweets-gifting',
    },
    {
      src: '/images/img4.jpg',
      alt: 'Image 4',
      description: 'Product Description 4',
      link: '/services/luxury-gifting',
    },
    {
      src: '/images/img5.jpg',
      alt: 'Image 5',
      description: 'Product Description 5',
      link: '/services/brass-items-as-corporate-gifts',
    },
    // Add more images as needed
  ];

  return (
    <div className="image-gallery-container">
      {images.map((image, index) => (
        <div className="image-item" key={index}>
          <div className="image-box">
            <a href={image.link} className="image-link">
              <img src={image.src} alt={image.alt} className="zoom-image" />
            </a>
          </div>
        </div>
      ))}

      {/* Centered container for the buttons */}
      <div className="button-container">
        <a
          href="https://wa.me/919990168777"
          target="_blank"
          rel="noopener noreferrer"
          className="product-buttons"
        >
          <img
            className="product-button1"
            src="/images/whatsaapicon.png"
            alt="WhatsApp Icon"
          />
        </a>

        <a
          className="anchors enquire-now-below"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            togglePopup();
          }}
        >
          <p className="image-price1">Enquire Now</p>
        </a>
      </div>

      <Popup showPopup={showPopup} togglePopup={togglePopup} />
    </div>
  );
};

export default SaleProduct;
