import React from "react";
import "./Policies.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <p className="privacy-policy-intro">
      Effective Date: 01 September 2024 
      Last Updated: 01 January 2025
      </p>
      <p className="privacy-policy-intro">
      This Privacy Policy outlines how Giftingy, a brand operated by Enlargenet, manages your personal information and respects your privacy. We are committed to safeguarding your personal data and ensuring compliance with applicable laws. This policy may be updated periodically, and any changes will be reflected on this page.
      </p>
      <section className="privacy-section">
        <h2>Registered Address:</h2>
        <address>
        <p>
        301C, 3rd Floor, Supermart 2, DLF Phase 4, Gurgaon, 122009 <br></br>
        Email:  <a href="mailto:vineet@giftingy.com">vineet@giftingy.com </a><br></br>
        Phone:  <a href="tel:+919990158777">+91 9990158777</a>

        </p>
        </address>
      </section>
      <section className="privacy-section">
        <h2>Collection of Personal Information</h2>
        <p>
        As a visitor to our website, you can engage in many activities without providing personal information. However, certain activities, such as placing orders, require you to provide mandatory information, including:
          <ul>
            <li>Name, address, phone number, and email address.</li>
            <li>Profile information, such as your password and interaction details with Giftingy.</li>
            <li>Transaction details related to your purchases.</li>
          </ul>
        </p>
        <p>
        Failure to provide mandatory information may restrict access to certain features or services.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Updating Your Personal Information</h2>
        <p>
        You can access, correct, or delete your personal information and privacy preferences at any time by visiting your profile section on our website or contacting us at vineet@giftingy.com. For security reasons, some updates may require identity verification. We will address your request promptly.
        </p>
      </section>
      <section className="privacy-section">
        <h2>How Your Personal Information Is Used</h2>
        <p>
        Giftingy collects and processes your personal data for the following purposes:
          <ul>
            <li>Fulfilling your orders and processing transactions.</li>
            <li>Responding to customer service inquiries and concerns.</li>
            <li>Providing updates on product availability, promotions, and services.</li>
            <li>Customising user experience and improving our offerings.</li>
            <li>Ensuring compliance with legal obligations.</li>
          </ul>
        </p>
        <p>Your personal information will not be sold, rented, or shared with third parties, except as outlined in this policy or required by law.</p>
        </section>
        <section className="privacy-section">
        <h2>Information Sharing with Service Providers</h2>
        <p>
        We work with service providers who assist in delivering our services, including payment processors, logistics partners, and customer support services. Only necessary information is shared with these entities, and they are required to protect your data and use it solely for the purposes specified by Giftingy.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Data Retention</h2>
        <p>
        We retain your personal data as long as your account remains active or as required to provide services and fulfil legal obligations. You can request account deletion or data discontinuation by emailing vineet@giftingy.com. Certain information may be retained to resolve disputes, enforce agreements, or comply with legal requirements.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Unsubscribe/Opt-Out Options</h2>
        <p>
        You can opt out of marketing communications by updating your preferences in the "My Profile" section or contacting us at vineet@giftingy.com. Service-related communications, such as order confirmations and legal updates, are mandatory and cannot be opted out of.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Protection of Your Personal Information</h2>
        <p>
        Giftingy employs advanced security measures to protect your data, including:</p>
          <ul>
            <li>Encryption during data transmission using secure protocols.</li>
            <li>Secure servers accessible only to authorised personnel.</li>
            <li>Regular security assessments to ensure data integrity.</li>
            
          </ul>
        
        <p>While we strive to safeguard your data, no system is completely secure. In case of concerns, please contact us at <a href="mailto:vineet@giftingy.com">vineet@giftingy.com.</a> </p>
        </section>

        <section className="privacy-section">
        <h2>Disclosure in Special Circumstances</h2>
        <p>
        We may disclose personal information:
          <ul>
            <li>To comply with legal requirements such as court orders or government requests.</li>
            <li>To protect Giftingy’s rights or investigate potential violations.</li>
            <li>For reasons of national security, law enforcement, or public importance.</li>
          </ul>
        </p>
        </section>

        <section className="privacy-section">
        <h2>Grievances and Queries</h2>
        <p>
        If you have concerns regarding compliance with Consumer Protection (E-Commerce) Rules, Consumer Protection Act, or Information Technology Rules, please contact our Compliance Officer:</p>
        <address>
            <p>
            <strong>Mr. Vineet Baveja</strong><br></br>
            Email: <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a> <br></br>
            Phone: <a href="tel:+919990158777">+91 9990158777</a>

            </p>
            <p>We will respond to your query within a reasonable timeframe.</p>
            <p><strong>Thank you for trusting Giftingy with your personal data. We value your privacy and are committed to protecting it.</strong></p>

        </address>
        
     </section>

      <footer className="privacy-footer">
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
