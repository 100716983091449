import React from "react";
import "./Policies.css";

const TermsAndPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Terms & Policy</h1>
      <p className="privacy-policy-intro">
      Effective Date: 01 September 2024
      Last Updated: 01 January 2025

      </p>
      <p className="privacy-policy-intro">
      By using this website, users unconditionally accept the terms and conditions outlined here and elsewhere on the site. Please read all information provided on products and services in the relevant sections and FAQs carefully before proceeding.
      </p>
      <p className="privacy-policy-intro">
      This website is owned and operated by Enlargenet under the brand Giftingy. Use of any material from this website or any related website owned, operated, controlled, or licensed by Enlargenet, its associates, or affiliates is prohibited without prior written permission. Unauthorised use of materials will constitute a violation of copyright and proprietary rights and may result in legal action under applicable laws in India.

      </p>
      
      <section className="privacy-section">
        <h2>Products and Services</h2>       
          <ul>
            <li>The products and services on this site are provided "as is" without warranties of any kind, either expressed or implied. Giftingy disclaims all such warranties to the fullest extent permitted by law.</li>
            <li>We do not guarantee error-free services, defect correction, or virus-free operation of the site or servers.</li>
            <li>Any warranties or after-sale services on specific products are provided directly by manufacturers/vendors. Giftingy is not obligated to handle such complaints or ensure compliance.</li>
            <li>Additional charges such as Octroi, taxes, or duties at the time of delivery, if applicable, must be borne by the recipient. Giftingy does not control or influence these charges.</li>
          </ul>
      </section>
      <section className="privacy-section">
        <h2>Specific Product Terms</h2>       
          <ul>
            <li><strong>Perishable Items:</strong> For flowers, sweets, or other perishable items, delivery is attempted only once. In cases of failed delivery due to incorrect or incomplete addresses, unavailability, or refusal, the customer will still be charged. Refunds will not be entertained.</li>
            <li><strong>Handmade Products: </strong> Variations in weight, size, colour, or design may occur as many products are handcrafted. Giftingy reserves the right to substitute similar or alternate products when necessary.</li>
            <li><strong>Images and Specifications:  </strong> Product images are for representation purposes only, and actual specifications may vary.</li>
            
          </ul>
      </section>
      <section className="privacy-section">
        <h2>Liability</h2>
        <p>Under no circumstances shall Giftingy or Enlargenet be liable for:</p>       
          <ul>
            <li>Loss of data, profits, or any consequential damages arising from the use of the website or its products/services.</li>
            <li>Any claims exceeding the amount paid by the user for a specific product or service.</li>
          </ul>
      </section>
      <section className="privacy-section">
        <h2>Prices and Availability</h2>     
          <ul>
            <li>Prices on the website are exclusive to online orders and may not reflect local retail prices.</li>
            <li>All prices and availability are subject to change without notice.</li>
            <li>Giftingy reserves the right to limit sales, including sales to re-sellers.</li>
          </ul>
      </section>

      <section className="privacy-section">
        <h2>User Feedback and Communication</h2>
        <p>
        Giftingy may contact users who have availed of its services via SMS, email, calls, or WhatsApp to collect feedback or provide product updates. Feedback collection remains the sole property of Giftingy.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Refund Policy</h2>
        <p>
        Refunds, if any, are at the sole discretion of Giftingy. Refunds are subject to a 10% deduction to account for bank charges and processing overheads. These do not set a precedent for future claims or create additional rights for claimants.
        </p>
      </section>
      <section className="privacy-section">
        <h2>Dispute Resolution</h2>
        <p>
        In case of disputes, parties must first attempt to resolve matters amicably within 30 days. If unresolved, disputes shall be referred to arbitration under the Arbitration and Conciliation Act, 1996. The jurisdiction for all disputes will be the courts in Gurgaon, India.
        </p>
      </section>

      <section className="privacy-section">
        <h2>Grievances and Queries</h2>
        <p>
        For compliance-related queries under the Consumer Protection Act, E-Commerce Rules, or IT Rules, please contact:</p>
        <address>
            <p>
            <strong>Mr. Vineet Baveja</strong> <br></br>
            Email: <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a> <br></br>
            Phone:  <a href="tel:+919990158777">+91 9990158777</a>

            </p>
            <p>Users are deemed to have read, understood, and accepted these terms and policies unconditionally by using this site. For clarifications, email us at <a href="mailto:vineet@giftingy.com">vineet@giftingy.com.</a></p>
            
        </address>
        
      </section>

      <footer className="privacy-footer">
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a>.
        </p>
      </footer>
    </div>
  );
};

export default TermsAndPolicy;
